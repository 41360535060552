.bossBars {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  top: 9px;
  left: 50%;
  transform: translate(-50%);
}

.bossbar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bossbar-title {
  font-size: 7px;
  color: #fff;
}
.bossbar {
  background-image: var(--bars-gui-atlas);
  width: 182px;
  height: 5px;
  position: relative;
}
.bossbar .fill {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 5px;
  width: 0;
  background-image: var(--bars-gui-atlas);
}
